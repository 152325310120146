<template>
  <div class="space-y-4 py-6 flex flex-col h-full px-8">
    <div class="flex flex-row items-center pb-2">
      <h4 class="h4 pr-5">{{ selectedMonitoringGroup.name }}</h4>
      <p
        class="px-3 py-1 rounded-md font-bold text-sm"
        :class="selectedMonitoringGroup.type === 'brand' ? 'blue-bg-3 blue-text-color' : 'red-bg-3 red-text-color'"
      >
        {{ selectedMonitoringGroup.type === 'brand' ? 'Brand' : 'Generic' }}
      </p>
    </div>
    <div class="space-y-6 pt-2">
      <page-tabs
        v-if="selectedMonitoringGroup.type === 'brand'"
        :tabs="availablePageTabs"
        :selected-tab="selectedTab"
        class="border-b-2 border-neutral-50"
      >
        <div
          v-for="tab in availablePageTabs"
          :key="tab.value"
          class="cursor-pointer z-10 duration-300 ease-linear"
          :class="{
            'border-bb-brand-purple text-bb-brand-purple': selectedTab === tab.value,
            'text-bb-text-default border-neutral-50 hover:border-neutral-100': selectedTab !== tab.value,
          }"
          style="border-bottom-width: 2px; margin-bottom: -2px"
        >
          <div
            class="px-6 flex gap-2 items-center pb-2"
            @click="selectTab(tab.value)"
          >
            <p>
              {{ tab.label }}
            </p>
          </div>
        </div>
      </page-tabs>
    </div>
    <div class="base-scrollbar overflow-y-auto flex-grow">
      <!-- Content based on the selected tab -->
      <div v-if="selectedTab === 'general'">
        <SettingsGeneral
          :selected-keywords="selectedKeywords"
          :campaign-name="selectedMonitoringGroup.campaignName"
          :initialized="selectedMonitoringGroup.initialized"
          @update:selectedKeywords="selectedKeywords = $event"
          @pause-monitoring="$emit('pause-monitoring')"
          @delete-monitoring="$emit('delete-monitoring')"
        />
      </div>
      <div v-if="selectedTab === 'infringements'">
        <settings-infringement
          :selected-keywords="selectedKeywords"
          @update:selectedKeywords="selectedKeywords = $event"
        />
      </div>

      <div v-if="selectedTab === 'smart-activation'">
        <settings-smart-activation
          :selected-keywords="monitoredKeywords"
          @update:selectedKeywords="updateSelectedKeywords"
          @update-keyword-smart-activation="updateKeywordSmartActivation"
        />
      </div>
    </div>
    <div class="flex justify-center pt-5">
      <merge-button-round
        button-type="secondary"
        class="min-w-60"
        @click="CloseModal"
        >Close</merge-button-round
      >
    </div>
  </div>
</template>
<script>
import SettingsGeneral from '../../settings-views/SettingsGeneral.vue'
import SettingsInfringement from '../../settings-views/SettingsInfringement.vue'
import SettingsSmartActivation from '../../settings-views/SettingsSmartActivation.vue'
import PageTabs from '@/components/shared/PageTabs.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'

export default {
  name: 'SelectedMonitoringSettings',
  components: {
    SettingsGeneral,
    SettingsInfringement,
    SettingsSmartActivation,
    PageTabs,
    MergeButtonRound,
  },
  props: {
    selectedMonitoringGroup: {
      type: Object,
      required: true,
    },
    activeTab: {
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      pageTabs: [
        { label: 'General', value: 'general' },
        { label: 'Smart Activation', value: 'smart-activation' },
      ],
      selectedKeywords: [
        { label: 'Keyword 1', value: 'keyword-1', status: 'DISABLED', selected: true, smartActivation: true },
        { label: 'Keyword 2', value: 'keyword-2', status: 'DISABLED', selected: true, smartActivation: true },
        { label: 'Keyword 3', value: 'keyword-3', status: 'DISABLED', selected: false, smartActivation: true },
        { label: 'Keyword 4', value: 'keyword-4', status: 'DISABLED', selected: false, smartActivation: true },
        { label: 'Keyword 5', value: 'keyword-5', status: 'DISABLED', selected: false, smartActivation: true },
        { label: 'Keyword 6', value: 'keyword-6', status: 'DISABLED', selected: false, smartActivation: true },
      ],
      // Initialize local selectedTab with activeTab prop value
      selectedTab: this.activeTab,
    }
  },
  computed: {
    availablePageTabs() {
      return this.selectedMonitoringGroup.type === 'brand'
        ? this.pageTabs
        : this.pageTabs.filter(tab => tab.value === 'general')
    },
    monitoredKeywords() {
      return this.selectedMonitoringGroup.keywords
    },
  },
  watch: {
    // Watch for changes in the activeTab prop and update the local selectedTab
    activeTab(newTab) {
      this.selectedTab = newTab
    },
  },
  methods: {
    selectTab(tabValue) {
      // When a tab is clicked, update the local selectedTab and emit the change to the parent
      this.selectedTab = tabValue
      this.$emit('update:activeTab', tabValue)
    },
    CloseModal() {
      this.$emit('close-modal')
    },
    updateSelectedKeywords(updatedKeywords) {
      this.selectedKeywords = [...updatedKeywords]
    },
    updateKeywordSmartActivation(keyword) {
      this.$emit('update-keyword-smart-activation', keyword)
    },
  },
}
</script>

<style scoped lang="scss"></style>
