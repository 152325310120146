import * as d3 from 'd3'

const COLORS = ['#38F1CA', '#65C8FF', '#AFADFF', '#FFB0C3']

export const createRankSvg = (id, data) => {
  const width = 96
  const height = 32

  // remove old svg
  d3.select(id).selectAll('*').remove()

  const svg = d3
    .select(id)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto;')

  const graphArea = svg.append('g').attr('transform', `translate(0, 0)`)

  const x = d3
    .scaleBand()
    .rangeRound([0, width])
    .domain(data.map(d => d.rank))
    .padding(0.3)

  const y = d3
    .scaleLinear()
    .range([height, 0])
    .domain([0, d3.max(data, d => d.count)])

  graphArea.append('g').attr('class', 'axis').attr('transform', `translate(0, ${height})`)

  graphArea.append('g').attr('class', 'axis')

  const rx = 2
  const ry = 2

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('visibility', 'hidden')
    .style('background', '#fff')
    .style('border', '1px solid #ccc')
    .style('padding', '5px')
    .style('border-radius', '4px')
    .style('box-shadow', '0 0 10px rgba(0,0,0,0.1)')
    .style('font-size', '12px')

  graphArea
    .selectAll('bar')
    .data(data)
    .enter()
    .append('path')
    .style('cursor', 'pointer')
    .style('fill', (d, i) => {
      return COLORS[i]
    })
    .attr(
      'd',
      item => `
        M${x(item.rank)},${y(item.count) + ry}
        a${rx},${ry} 0 0 1 ${rx},${-ry}
        h${x.bandwidth() - 2 * rx}
        a${rx},${ry} 0 0 1 ${rx},${ry}
        v${height - y(item.count) - ry}
        h${-x.bandwidth()}Z
      `,
    )
    .on('mouseover', function (event, d) {
      tooltip.style('visibility', 'visible').text(`${d?.percentage ?? 0}%`)
      d3.select(this).transition().duration(200).attr('stroke-width', 2)
    })
    .on('mousemove', function (event) {
      tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px')
    })
    .on('mouseout', function () {
      tooltip.style('visibility', 'hidden')
      d3.select(this).transition().attr('stroke-width', 0)
    })
}
