<template>
  <div class="space-y-5">
    <div class="grid grid-cols-3 pt-2 gap-x-10">
      <div class="flex flex-col justify-between col-span-1">
        <div class="flex flex-col">
          <p class="font-medium">Manage Smart Activation</p>
          <p class="text-bb-neutral-gray pt-1">
            We will automatically pause the keyword when no other competitor is bidding
          </p>
        </div>
        <div class="flex flex-col gap-y-2 pt-10">
          <ic-info-solid class="w-4 h-4 text-bb-brand-purple" />
          <p class="text-bb-neutral-gray p3">
            Smart activation is only possible for keywords that you <span class="font-bold">actively bid</span> on in
            your account
          </p>
        </div>
      </div>
      <div class="col-span-2">
        <div class="flex flex-row gap-x-2 px-10 pb-6 justify-between">
          <p class="font-medium">Keywords</p>
          <p class="font-medium">Smart Activation</p>
        </div>
        <div class="flex flex-col justify-center gap-y-3 px-10">
          <ul
            v-for="keyword in sortedSelectedKeywords"
            :key="keyword.keyword"
          >
            <li>
              <div class="flex flex-row justify-between w-full border-b pb-3">
                <div class="flex flex-row items-center gap-x-5 max-w-60 flex-grow truncate min-h-12">
                  {{ keyword.keyword }}

                  <div
                    v-if="keyword.smartSavingActive"
                    class="bg-bb-blue-pale rounded-md p-0.5"
                  >
                    <ic-guard class="w-5 h-5 text-bb-blue" />
                  </div>
                </div>
                <slider-switch
                  :id="keyword.keyword"
                  type="keyword"
                  :status="keyword.smartSavingActive ? 'ENABLED' : 'DISABLED'"
                  @update-status="handleSmartActivation(keyword)"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderSwitch from '@/components/input/brightbid/SliderSwitch.vue'

import IcInfoSolid from '@/components/icon/brightbid/ic-info-solid.vue'
import IcGuard from '@/components/icon/brightbid/IcGuard.vue'

export default {
  name: 'SettingsSmartActivation',
  components: { SliderSwitch, IcInfoSolid, IcGuard },
  props: {
    selectedKeywords: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { localSelectedKeywords: [...this.selectedKeywords] }
  },
  computed: {
    sortedSelectedKeywords() {
      return [...this.selectedKeywords].sort((a, b) => a.keyword.localeCompare(b.keyword))
    },
  },
  methods: {
    handleSmartActivation(keyword) {
      this.$emit('update-keyword-smart-activation', keyword)
    },
  },
}
</script>
